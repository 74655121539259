<template>
  <div class="withdrawDetails">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">提现明细</div>
      <div class="rightBtn"></div>
    </div>
    <div class="detailMain">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="detailItem" v-for="item in list" :key="item.id">
          <div class="detailInfo">
            <div class="detailTitle">订单编号：{{ item.id }}</div>
            <div class="copyBtn" @click="copyId(item)">复制</div>
          </div>
          <div class="gold">
            <span>¥</span>
            {{ item.money / 100 }}
          </div>
          <div class="bottomBox">
            <div class="contentBox">
              <div class="detailDesc">{{ converType(item.payType) }}提现 - {{ converStatus(item.status) }}</div>
              <div class="timer">提现时间：{{ item.createdAt | time }}</div>
            </div>
            <div class="reasonBtn" v-if="item.statusDesc" @click="lookReason(item)">查看原因</div>
          </div>
        </div>
      </PullRefresh>
    </div>

    <div class="actTip">
      <van-overlay :show="showReason">
        <div class="reason">原因</div>
        <div class="actTipBox">
          <div class="info">
            <p>尊敬的用户：</p>
            <p class="statusDesc">{{ statusDesc }}</p>
          </div>
          <div class="close" @click="closeReason">
            <svg-icon icon-class="close4" />
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>

<script>
import { Overlay, Toast } from 'vant';
import { queryWithdrawDetails } from '@/api/mine';
import PullRefresh from '@/components/PullRefresh';
export default {
  name: 'withdrawDetails',
  components: {
    PullRefresh,
    [Overlay.name]: Overlay,
  },
  data() {
    return {
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
      showReason: false,
      statusDesc: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(queryWithdrawDetails, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },

    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    copyId(item) {
      this.$copyText(item.id).then(
        (e) => {
          Toast('复制成功');
        },
        (e) => {
          Toast('复制失败');
        },
      );
    },
    lookReason(item) {
      this.statusDesc = item.statusDesc;
      this.showReason = true;
    },
    closeReason() {
      this.showReason = false;
      this.statusDesc = '';
    },
    converType(val) {
      switch (val.toLowerCase()) {
        case 'alipay':
          return '支付宝';
        case 'bankcard':
          return '银行卡';
        case 'usdt':
          return 'USDT';
        default:
          return '其他';
      }
    },
    converStatus(val) {
      switch (val) {
        case 1:
          return '未审核';
        case 2:
          return '审核通过，转账中';
        case 3:
          return '已拒绝';
        case 4:
          return '未知错误';
        case 5:
          return '提现成功';
        case 6:
          return '提现失败';
        default:
          return '未知';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawDetails {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .detailMain {
    padding: 0 16px;
    height: calc(100% - 44px);

    .detailItem {
      background-color: #fff;
      padding: 6px 12px;
      margin-top: 20px;
      border-radius: 6px;
      .detailInfo {
        display: flex;
        align-items: center;
        .detailTitle {
          font-size: 15px;
        }
        .copyBtn {
          padding: 2px 2px 2px 4px;
          color: rgb(255, 103, 143);
        }
        .avatarImg {
          width: 43px;
          height: 43px;
        }
        .detailOpt {
          flex: 2;
          margin: 0 10px;
          span {
            opacity: 0.6;
          }
        }
        .timer {
          font-size: 14px;
          color: #000;
        }
      }
      .gold {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(255, 103, 143);
        font-size: 30px;
        span {
          font-size: 20px;
          display: inline-block;
          margin-top: 8px;
        }
      }

      .bottomBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .contentBox {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: rgb(102, 102, 102);
          .detailDesc {
            width: 215px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #666666;
          }
          .timer {
            font-size: 14px;
            color: #666666;
          }
        }

        .reasonBtn {
          width: 80px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          border-radius: 28px;
          box-sizing: border-box;
          color: rgb(255, 103, 143);
          border: 1px solid rgb(255, 103, 143);
        }
      }
    }
  }

  .actTip {
    /deep/ .van-overlay {
      z-index: 9999;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .reason {
      font-size: 20px;
      background-color: #fff;
      width: 340px;
      height: 40px;
      color: rgb(132, 132, 132);
      line-height: 40px;
      text-align: center;
      border-radius: 4px 4px 0 0;
    }
    .actTipBox {
      width: 340px;
      padding-bottom: 30px;
      text-align: center;
      margin-bottom: 10%;
      position: relative;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      .info {
        color: #425453;
        padding: 0 20px;
        box-sizing: border-box;
        text-align: left;
        font-size: 18px;

        p {
          margin-bottom: 8px;
        }

        .statusDesc {
          font-size: 16px;
        }
      }

      .close {
        position: absolute;
        font-size: 32px;
        bottom: -60px;
        left: 50%;
        margin-left: -16px;
      }
    }
  }
}
</style>
